import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Logo from "../../logo"

let defaultWidth

if (typeof window !== `undefined`) {
  defaultWidth = window.innerWidth
}

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(defaultWidth)

  const handleWindowResize = () => {
    setWindowWidth(defaultWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return windowWidth
}

const desktopLinks = () => (
  <>
    <Link
      to="#about"
      style={{
        margin: `0 5px`,
        textDecoration: `none`,
        color: `#000`,
        cursor: `pointer`,
      }}
    >
      About
    </Link>
    <Link
      to="#music"
      style={{
        margin: `0 5px`,
        textDecoration: `none`,
        color: `#000`,
        cursor: `pointer`,
      }}
    >
      Music
    </Link>
    <Link
      to="#merch"
      style={{
        margin: `0 5px`,
        textDecoration: `none`,
        color: `#000`,
        cursor: `pointer`,
      }}
    >
      Merch
    </Link>
    <Link
      to="#socials"
      style={{
        margin: `0 5px`,
        textDecoration: `none`,
        color: `#000`,
        cursor: `pointer`,
      }}
    >
      Socials
    </Link>
  </>
)

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#fff`,
      position: `fixed`,
      width: `100%`,
      zIndex: `1`,
    }}
  >
    <section
      style={{
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        height: 60,
      }}
    >
      <Link
        to="/"
        style={{
          width: 60,
          height: `100%`,
          padding: `10px`,
          margin: `0 10px`,
          textDecoration: `none`,
          cursor: `pointer`,
        }}
      >
        <Logo />
      </Link>
      <div
        className="header-right-container"
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          marginRight: 10,
        }}
      >
        <a
          href="https://turtlet.bandcamp.com/track/facade-feat-pak"
          style={{
            height: 60,
            padding: `10px`,
            textDecoration: `none`,
            cursor: `pointer`,
          }}
        >
          <img
            style={{
              height: `100%`,
            }}
           src="https://i.imgur.com/DIBbiHH.jpg" alt="facade icon" />
        </a>

        {useWindowWidth() < 500 ? "" : desktopLinks()}
      </div>
    </section>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
